import * as React from 'react';
import { actions } from '../../../../ducks/MessageForm';
import { SimpleFormLabel } from '../../../../lib/components/SimpleForm';
import { getDispatch } from '../../../../lib/dispatchExporter';
import I18n from '../../../../lib/i18n';
import MainContent from '../../../../models/MainContent';
import MessageDetail from '../../../../models/MessageDetail';
import Movie from '../../../../models/Movie';
import InputForm from './InputForm';
import notify from '../../../../lib/notify';

interface IProps {
  label: string;
  index: number;
  parentFormName: string;
  parentResource: MessageDetail | MainContent;
}
interface IState {
  movieUrl: string;
}
export default class MainMovieForm extends React.PureComponent<IProps, IState> {
  private dispatch = getDispatch();

  constructor(props: IProps) {
    super(props);
    this.state = {
      movieUrl: '',
    };
  }

  public handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ movieUrl: e.target.value });
  }

  public handleClick = () => {
    const { movieUrl } = this.state;
    const { parentResource } = this.props;
    const updateKey = this.getUpdateKey();
    const movieIndex = parentResource.main_movie_attributes.movies.size - 1;

    // メタID英数字64桁しか受け付けない
    if (!movieUrl.match(/^[0-9a-f]{64}$/)) {
      notify({
        title: '',
        message: I18n.t('activerecord.errors.message_detail.invalid_movie_url'),
        type: 'danger'
      });
      return false;
    }

    if (!parentResource.main_movie_attributes.movies.filter(e => !e._destroy).isEmpty()) {
      this.dispatch(actions.deleteMovie({ key: updateKey, index: movieIndex }));
      this.dispatch(actions.addMovie({ key: updateKey, movieUrl }));
    } else {
      this.dispatch(actions.addMovie({ key: updateKey, movieUrl }));
    }
    this.setState({ movieUrl: '' });
  }

  public getUpdateKey = () => {
    const { parentFormName } = this.props;
    return(
      ['messageDetails'].concat(parentFormName.replace(/]/g, '').split('[').slice(2)).concat(['main_movie_attributes']));
  }

  public renderMainMovieCard = () => {
    const { parentResource, parentFormName } = this.props;
    const { main_movie_attributes: mainMovie } = parentResource;
    const updateKey = this.getUpdateKey();

    return mainMovie.movies.map((movie: Movie, index: number) => {
      const formName = `${parentFormName}[main_movie_attributes][${index}]`;
      return(
        <InputForm key={index} {...{ movie, updateKey, movieIndex: index, formName}}/>
      );
    });
  }

  public render() {
    const { label, parentResource } = this.props;
    const { movieUrl } = this.state;
    const { main_movie_attributes: mainMovie } = parentResource;
    const errorMessage = mainMovie ? mainMovie.errors : [] ;

    return (
      <div style={{ marginBottom: '25px' }}>
        <SimpleFormLabel
          label={label}
          errorMessage={Object.values(errorMessage)}
          popoverText={I18n.t('message_details.tooltips.main_movie')}
        >
          <div className="row">
            <div className="col-md-6">
              <input type="text" className="form-control" value={movieUrl} onChange={this.handleChange} />
            </div>
            <div className="col-md-6" style={{ marginBottom: '10px' }}>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.handleClick}
                disabled={!movieUrl}
              >
                {I18n.t('common.action.update')}
              </button>
            </div>
          </div>
        </SimpleFormLabel>
        { this.renderMainMovieCard()}
      </div>
    );
  }
}
