import { List, Record } from 'immutable';
import applySortActionList from '../lib/applySortActionList';
import I18n from '../lib/i18n';
import notify from '../lib/notify';
import Movie from './Movie';

const defaultValue: {
  movies: List<Movie>,
  validators: any,
  errors: any,
} = {
  movies: List(),
  validators: {},
  errors: {},
};

export default class MovieList extends Record(defaultValue) {
  public updateByIndex(index: string, key: string, value: string) {
    return this.updateIn(['movies', index], movie => movie.set(key, value));
  }

  public add(movieMetaId: string, nemoEnv: string, name: string = '') {
    // メタIDからURLを生成する
    const url = this.generateUrl(nemoEnv, movieMetaId);
    const movie = new Movie({ url, name });
    if (!movie.validateUrl()) { return this; }
    if (!this.validateLimitRelatedMovies()) { return this; }
    if (!this.validateDuplicateUrl(url)) { return this; }
    return this.update('movies', movies => movies.push(new Movie(movie)));
  }

  public delete(index: string) {
    const deletedMovie = this.getIn(['movies', index]);
    if (deletedMovie.id === undefined || deletedMovie.id === null) {
      return this.deleteIn(['movies', index]);
    } else {
      return this.updateIn(['movies', index], (movie: Movie) => movie.set('_destroy', true));
    }
  }

  public switch(oldIndex: number, newIndex: number) {
    return this.update(
      'movies',
      movies => applySortActionList(movies, oldIndex, newIndex)
    );
  }

  public displayMovies() {
    return this.movies.filter(movie => movie.visible());
  }

  public exists(url: string) {
    return !(this.displayMovies().find(movie => movie.url === url) === undefined);
  }

  public validateDuplicateUrl(url: string) {
    if (this.exists(url)) {
      notify({
        title: I18n.t('activerecord.attributes.message_detail.related_movies'),
        message: I18n.t('activerecord.errors.movie.duplicate_movie_url'),
        type: 'danger',
      });
      return false;
    }
    return true;
  }

  public validateLimitRelatedMovies() {
    if (this.validators.maximum <= this.displayMovies().size) {
      notify({
        title: I18n.t('activerecord.attributes.message_detail.related_movies'),
        message: I18n.t('activerecord.errors.movie.limit_movie_number'),
        type: 'danger',
      });
      return false;
    }
    return true;
  }

  public validateName() {
    // 各movieの名前が全部空もしくは全部入力されているかどうかを判定
    // toSet().toList()でlistの値をuniqに変換
    // 空と空でないものが入り交じるとuniqをかけたらsizeが2になるのでinvalid
    return this.displayMovies().map(movie => movie.nameEmpty()).toSet().toList().size === 1;
  }

  public generateUrl(nemoEnv: string, movieMetaId: string) {
    return `https://nemof.hac.${nemoEnv}.nemo.srv.nintendo.net/v1/movie-meta/${movieMetaId}/profiles/168494c0-f7e5-4337-9017-80ac5b308e55/movie-file/movie.mp4`;
  }
}
