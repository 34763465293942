import { Record } from 'immutable';
import moment from 'moment-timezone';
import I18n from '../lib/i18n';
import lengthValidator from '../lib/lengthValidator';
import notify from '../lib/notify';

const defaultValue: {
  id?: number,
  ncms_video_id?: number,
  url: string,
  name?: string,
  image?: string,
  distribution_start_datetime?: number,
  distribution_end_datetime?: number,
  _destroy: boolean,
  errors: { [s: string]: string[] },
  validators: { name: { maximum: number } },
} = {
  id: undefined,
  ncms_video_id: undefined,
  url: '',
  name: undefined,
  image: undefined,
  distribution_start_datetime: undefined,
  distribution_end_datetime: undefined,
  _destroy: false,
  errors: {},
  validators: {
    name: { maximum: 64 },
  },
};

export default class Movie extends Record(defaultValue) {
  // movie-metaとprofilesの間がmovieMetaIdとなり、そのフォーマットは^[0-9a-f]{64}$
  public static REGEXP_URL = /^https:\/\/nemof\.hac\.(.+)\.nemo\.srv\.nintendo\.net\/v(\d)\/movie-meta\/[0-9a-f]{64}\/profiles\/168494c0-f7e5-4337-9017-80ac5b308e55\/movie-file\/movie\.mp4$/;

  public digest(): string | undefined {
    // nemoURLの場合はメタID、bugyoURLの場合はファイル名を抽出
    if (this.url.startsWith('https://nemof.')) {
      return this.url.split('/')[5];
    } else {
      const digest = this.url.split('/').pop();
      if (digest === undefined) { return undefined; }
      return digest.split('.').shift();
    }
  }

  public nameEmpty(): boolean {
    return this.name === '' || this.name === undefined;
  }

  public visible(): boolean {
    const { _destroy: destroy } = this;
    return !destroy;
  }

  public validateUrl(): boolean {
    if (!this.url.match(Movie.REGEXP_URL)) {
      notify({
        title: '',
        message: I18n.t('activerecord.errors.message_detail.invalid_movie_url'),
        type: 'danger'
      });
      return false;
    }
    return true;
  }

  public validateName(): Movie {
    return lengthValidator<Movie>('name', this);
  }

  public humanStartDatetime(timezone: string) {
    return this.humanDateTime(timezone, this.distribution_start_datetime);
  }

  public humanEndDatetime(timezone: string) {
    return this.humanDateTime(timezone, this.distribution_end_datetime);
  }

  private humanDateTime(timezone: string, datetime?: number) {
    if (datetime) {
      return moment(datetime).tz(timezone).format('Y-MM-DD HH:mm Z');
    }
    return '';
  }
}
